<template>
  <div id="contacts"> 
    <contacts-list></contacts-list>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'
  import ContactsList from './components/ContactsList'

  export default{
    name: 'Contacts',
    components: {ContactsList},
    beforeMount() {
      this.fetchProjects()
    },
    methods: {
      ...mapActions('profile',['fetchProjects']),
    },
  }
</script>
