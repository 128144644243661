<template>
  <div id="contacts-list"> 
    <b-row>
      <b-col cols="12">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <b-row>  
              
              <b-col xl="4" sm="12">
                <b-form-group label="Inmobiliarias" label-for="inmobiliarias">
                  <v-select
                    v-model="filtersForm.real_state_agency"
                    label="name"
                    placeholder="Selecciona una inmobiliaria"
                    :options="realStateAgencies"
                    :reduce="realStateAgencies => realStateAgencies.id">
                    <span slot="no-options">
                      No se encontraron opciones
                    </span>
                  </v-select>
                </b-form-group>
                
              </b-col>   

              <b-col xl="4" sm="12">
                <b-form-group label="Proyecto" label-for="status">
                  <v-select
                    v-model="filtersForm.project"
                    label="name"
                    placeholder="Selecciona un Proyecto"
                    :options="filteredProjects"
                    :reduce="filteredProjects => filteredProjects.id"
                    :disabled="filtersForm.real_state_agency == '' || filtersForm.real_state_agency == null"
                    >
                    <span slot="no-options">
                      No se encontraron opciones
                    </span>
                  </v-select>
                </b-form-group>
              </b-col>  

                <b-col xl="4" sm="12">
                  <b-form-group label="Fecha de Inicio" label-for="status">
                    <b-form-datepicker
                    locale="es"
                    id="date_ini" 
                    reset-button
                    placeholder="Fecha de inicio"
                    v-model="filtersForm.date_init"/>
                  </b-form-group>
                </b-col>

                <b-col xl="4" sm="12">
                  <b-form-group label="Fecha de Termino" label-for="status">
                    <b-form-datepicker
                    locale="es"
                    id="date_end" 
                    reset-button
                    placeholder="Fecha de Termino"
                    v-model="filtersForm.date_end"/>
                  </b-form-group>
                </b-col>

              
 
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" class="mt-2">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <b-overlay :show="overlayList" opacity="1.0" spinner-small spinner-variant="primary" @hidden="_onHidden()">
              <div class="d-flex align-items-end justify-content-end">
                <b-overlay :show="overlayForm" opacity="0.5" spinner-small spinner-variant="primary" class="d-inline-block" @hidden="_onHidden()" >
                  <b-button class="mr-1" ref="button" variant="primary" :disabled="overlayForm" @click.prevent="_exportContacts()" v-if="permissions_visible.download_contacts">
                    <feather-icon icon="DownloadIcon" class="mr-50"/>
                    <span class="align-middle">Descargar contactos</span>
                  </b-button>
                </b-overlay>
              </div>

              
              <div class="scroller mb-1">
                <b-table
                  hover
                  striped
                  show-empty
                  responsive="xl"
                  class="position-relative mb-1 mt-2 has-text-centered tablefix"
                  :items="contacts.data"
                  :fields="tableColumns"
                  empty-text="Datos no Disponibles"
                > 
                  
                  <template #cell(created_at)="data">{{ data.item.created_at | moment("DD/MM/YYYY") }}</template> 
                  <template #cell(project)="data"><span v-if="data.item.project != null">{{data.item.project.name}}</span></template>

                </b-table>
                <b-pagination align="right" v-model="currentPage" hide-goto-end-buttons :total-rows="contacts.total" :per-page="contacts.per_page" />
              </div>
            </b-overlay>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {mapActions, mapGetters, mapState} from 'vuex'
  import ToastificationContent from "@core/components/toastification/ToastificationContent";

  export default {
    name: 'ContactsList',
    data () {
      return {
      projects_types:{
       
      },
      permissions_visible:{
        edit_contacts:false,
        show_contacts:false,
        download_contacts:false,
        edit_contacts:false,
      },
        overlayForm: false,
        overlayList: true,
        currentPage: 1,
        filtersForm: { 
          real_state_agency:"",
          project:"",
          type:"",
          date_init:"",
          date_end:"",
          page: 1,  
        },
        statusList: [
          {id:'1',name:'Externo'},
          {id:'0',name:'Interno'},
        ],
        tableColumns: [
          {key: 'id', sortable: false, label: 'ID'},
          {key: 'name', sortable: false, label: 'Nombre'},
          {key: 'rut', sortable: false, label: 'RUT'},
          {key: 'email', sortable: false, label: 'Email'},
          {key: 'project', sortable: false, label: 'Proyecto'},
          {key: 'phone', sortable: false, label: 'Teléfono'},
          //{key: 'query', sortable: false, label: 'Consulta'},
          {key: 'created_at', sortable: true, label: 'Fecha'},
          //{key: 'actions', sortable: false, label: 'Acciones'},
        ],
        filteredProjects:[]
      }
    },
    mounted(){
      this.permissions_visible = this.$PermissionHelper.checkPermissions(this.permissions_visible);
      this.filteredProjects = this.projects;
    },
    beforeMount() { 
      this._fetchContacts(this.filtersForm)
    },
    watch: {
      "filtersForm.status": {handler(newval){this._fetchContacts(this.filtersForm)}},
      "filtersForm.real_state_agency": {handler(newval){
        if(newval == null){ 
          this.filteredProjects = this.projects;
        }else{ 
          this.filteredProjects = [];
          this.filtersForm.project = null;
          this.projects.map((valor) => {
            if(valor.realstateagency.id == newval){
                this.filteredProjects.push(valor)
              }
          })
        }
        this.filtersForm.project = "";
        this._fetchContacts(this.filtersForm)
      }},
      "filtersForm.project": {handler(newval){this._fetchContacts(this.filtersForm)}},
      "filtersForm.type": {handler(newval){this._fetchContacts(this.filtersForm)}},
      
      "filtersForm.date_end": {handler(newval){this._fetchContacts(this.filtersForm)}},
      currentPage (val) {
        this.filtersForm.page = this.currentPage
        this._fetchContacts(this.filtersForm)
      }
    },
    computed: { 
      ...mapState('contacts',['contacts']),
      ...mapGetters('profile',['projects','realStateAgencies']),
    },
    methods: {
      ...mapActions('contacts',['fetchContacts','exportContacts']),
      async _exportContacts() {
        this.overlayForm = true
        await this.exportContacts(this.filtersForm)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se ha enviado un correo con el archivo',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          })
        }).catch(err => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Problemas al exportar el archivo',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          this.overlayForm = false
        })
      }, 
      
      async _fetchContacts(filter) {
        this.overlayList = true
        await this.fetchContacts(filter)
        .then((res) => {
           
        })  
        .finally(() => {
          this.overlayList = false
        })
      },
 
        
      _editContact(newobj) {
        //let data = { name: 'news-details', params: { id: newobj.id, newsingle: newobj }};
        //this.$router.push( data )
      },
      _onHidden() {
        //this.$refs.button.focus()
      }, 
    },
  }
</script>
<style>
.tablefix tr{
  height: 61px !important;
} 
</style>